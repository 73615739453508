<template>
  <v-card class="pa-2 fill-height" style="min-height: 80vh; max-height: 80vh; overflow-y: hidden; display: flex; flex-direction: column">
    <v-card-title class="pb-2" style="display: flex;">
      <span>{{ $lang.header.addConnectedResources }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters class="px-2 pb-2" style="flex: unset">
      <v-btn
        :color="all ? 'primary' : 'grey'"
        @click="selectAll()"
      >
        {{ $lang.labels.all }}
      </v-btn>
      <v-btn
        class="ml-2"
        :color="none ? 'primary' : 'grey'"
        @click="selectNone()"
      >
        {{ $lang.labels.none }}
      </v-btn>
    </v-row>
    <div style="height: 100% !important; width: 100%; display: flex; flex-grow: 1;">
      <v-row no-gutters style="max-height: calc(80vh - 209px) !important; overflow-y: auto">
        <v-col cols="12" class="px-2">
          <v-data-table
            :key="tableKey"
            :search="search"
            :headers="headers"
            :items="dataForTable"
            item-key="id"
            class="elevation-0 background-transparent"
            :footer-props="{
              'items-per-page-options': rowsPerPageItemsGlobal
            }"
          >
            <template v-slot:top>
              <div class="d-flex flex-row justify-space-between align-center">
                <v-select
                  v-model="filterTypeValue"
                  :items="allTypes"
                  :label="$lang.labels.type"
                  item-text="name"
                  item-value="value"
                  style="max-width: 33%;"
                  class="mr-4 mt-1 pt-2 align-self-center"
                  outlined
                  dense
                  clearable
                ></v-select>
                <v-text-field
                  v-model="search"
                  density="compact"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  variant="solo-filled"
                  class="mt-0 pt-0"
                  flat
                  hide-details
                  single-line
                  clearable
                ></v-text-field>
              </div>

            </template>
            <template v-slot:item.switch="{ item }">
              <v-switch
                :key="tableKey"
                v-model="item.isSelected"
                class="module-auto-add-table-switch align-self-center mb-0"
              />
            </template>

            <template v-slot:item.type="{ item }">
              <div class="d-flex align-center">
                <v-icon class="mr-2">{{ item.icon }}</v-icon>
                <span>{{ $lang.status[item.type] }}</span>
              </div>
            </template>

            <template v-slot:item.name="{ item }">
              <div>{{ item.name }}</div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="py-4" style="display: flex;">
      <v-row justify="space-between">
        <v-btn
          color="error"
          text
          large
          @click="$emit('closeDialog', true)"
        >
          {{ $lang.actions.cancel }}
        </v-btn>
        <v-btn
          color="primary"
          text
          large
          @click="$emit('save', dataForAdding.filter(x => x.isSelected)); $emit('closeDialog', true)"
        >
          {{ $lang.actions.add }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dataForTable: [],
      dataBackup: [],
      search: '',
      allTypes: [],
      filterTypeValue: '',
      all: false,
      none: false,
      tableKey: 0,
      headers: [
        { text: '', value: 'switch', sortable: false },
        { text: this.$lang.header.type,
          value: 'type',
          sortable: true,
          filterable: false
        },
        {
          text: this.$lang.labels.name,
          value: 'name',
          sortable: true
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal']),
    dataForAdding() {
      return this.data.map((item) => ({
        ...item,
        isSelected: this.dataForTable
          .find((innerItem) => innerItem.id === item.id)
          .isSelected 
        || item.isSelected
      }))
    }
  },
  watch: {
    dataForTable: {
      handler (val) {
        const checkerTrue = (arr) => arr.every((v) => v.isSelected === true)
        const checkerFalse = (arr) => arr.every((v) => v.isSelected === false)

        this.all = checkerTrue(val)
        this.none = checkerFalse(val)
      },
      deep: true
    },
    filterTypeValue(val) {
      if (val) {
        this.dataForTable = this.data
          .filter((item) => item.type.includes(val))
          .map(this.convertDataForTable)
      } else {
        this.dataForTable = this.data.map(this.convertDataForTable)
      }
    }
  },
  mounted() {
    this.dataBackup = this.data
    this.dataForTable = this.data.map(this.convertDataForTable)
    this.allTypes = this.dataForTable.map((item) => ({
      name: this.$lang.status[item.type] || item.type,
      value: item.type
    }))
  },
  methods: {
    convertDataForTable(item) {
      return {
        id: item.id,
        isSelected: item.isSelected,
        name: item.data.name,
        type: item.type
      }
    },
    selectAll() {
      this.none = false
      this.dataForTable.forEach((x) => x.isSelected = true)
    },
    selectNone() {
      this.all = false
      this.dataForTable.forEach((x) => x.isSelected = false)
    }
  }
}
</script>
<style lang="scss">
#data-text {
  height: 57vh !important;
}
.module-auto-add-table-switch .v-input__slot {
  margin-bottom: 0 !important;
}
</style>
