<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-row wrap no-gutters>
        <v-col cols="6" class="pr-1">
          <p class="pb-1 mb-0">{{ $lang.labels.searchResources }}</p>
          <v-text-field
            :key="`${type}-add`"
            v-model="searchFromBE"
            :data-cy="`module-search-${type}`"
            prepend-inner-icon="mdi-cloud-search-outline"
            :placeholder="$lang.actions.startTyping"
            :label="`${$lang.status[type]} ${$lang.labels.global}`"
            class="mb-1"
            clearable
            outlined
            dense
            hide-details
          />
          <div v-if="listFromBE && listFromBE.length > 0" class="pa-1 list-div">
            <template v-for="(item, i) in sortedFromBe">
              <v-text-field
                :key="`${type}-list-item-${i}`"
                v-model="item.name"
                class="my-1"
                append-outer-icon="mdi-chevron-right"
                append-icon="mdi-open-in-new"
                outlined
                dense
                readonly
                hide-details
                @click:append="openItem(item)"
              >
                <template v-slot:append-outer>
                  <v-icon
                    :disabled="searchingForConnectedResources"
                    @click="selectedFromBE = item.id, addFromBeToModule()"
                  >
                    {{ 'mdi-chevron-right' }}
                  </v-icon>
                </template>
              </v-text-field>
            </template>
          </div>
          <div v-else class="pa-1 list-div">
            <p>{{ $lang.labels.noResultsFound }}</p>
          </div>
        </v-col>
        <v-col cols="6" class="pl-1">
          <p class="pb-1 mb-0">{{ $lang.labels.addedResources }}</p>
          <v-text-field
            :key="`${type}-filter`"
            v-model="filterAdded"
            :data-cy="`module-filter-${type}`"
            prepend-inner-icon="mdi-filter-outline"
            :placeholder="$lang.labels.filter"
            :label="$lang.labels.filter"
            class="mb-1"
            clearable
            outlined
            dense
            hide-details
          />
          <div v-if="selectedFromModule && selectedFromModule.length > 0" class="pa-1 list-div">
            <template v-for="(item, i) in filteredAndSortedAdded">
              <v-text-field
                :key="`${type}-item-${i}`"
                v-model="item.name"
                class="my-1 clickable-simple"
                append-outer-icon="mdi-trash-can-outline"
                append-icon="mdi-open-in-new"
                outlined
                dense
                readonly
                hide-details
                @click:append-outer="removeFromSelected(item.id)"
                @click:append="openItem(item)"
              />
            </template>
          </div>
          <div v-else class="pa-1 list-div">
            <p>{{ $lang.labels.noResourcesAdded }}</p>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-if="searchingForConnectedResources"
      v-model="searchingForConnectedResources"
      max-width="30%"
      persistent
    >
      <loading-modal
        :loading-text="$lang.hints.searchingForConnectedResources"
        :action-text="$lang.hints.dataTooLArge"
        :loading="searchingForConnectedResources"
      />
    </v-dialog>
    <v-dialog v-if="showModal" v-model="showModal" max-width="71%" persistent>
      <add-modal :data="dataForModal" @save="afterModal($event)" @closeDialog="showModal = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import LoadingModal from '../../components/ui/modals/LoadingModal.vue'
import AddModal from './AutoAddModal'
import { fetcherDataByType, getFormattedRelatedResources } from '../modules/modulesLogic.js'
import { mapActions } from 'vuex'

export default {
  components: {
    AddModal,
    LoadingModal
  },
  props: {
    type: {
      type: String,
      default: () => ''
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    allResources: {
      type: Array,
      default: () => []
    },
    defaultValues: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      fetcherDataByType,
      lock: true,
      isLoading: false,
      listFromBE: [],
      selectedFromBE: '',
      searchFromBE: '',
      listFromModule: [],
      selectedFromModule: [],
      resourcesTypes: {
        GLOBAL_TRANSLATION: 'templates/translations/edit',
        MODULE: 'modules/local/edit',
        PROCESS: 'processes/edit',
        PROCESS_CREDENTIAL: 'credentials/edit',
        SETTING: 'settings/edit',
        TEXT_TEMPLATE: 'templates/templates/edit',
        TRIGGER_CRON: 'triggers/cron/edit',
        TRIGGER_REST: 'triggers/rest/edit',
        VALIDATION_RULE: 'validations/edit',
        PLUGIN: 'plugins/edit',
        TRIGGER_MESSAGING: 'triggers/messaging/edit',
        LIBRARY: 'libraries/edit',
        TRIGGER_EVENT_HANDLER: 'triggers/event-handler/edit',
        ENTITY: 'entities/edit'
      },
      resourcesTypesNoSystem: [
        'PROCESS',
        'TRIGGER_REST',
        'VALIDATION_RULE'
      ],
      showModal: false,
      dataForModal: [],
      filterAdded: '',
      searchingForConnectedResources: false
    }
  },
  computed: {
    filteredAndSortedAdded() {
      if (this.filterAdded) {
        return this.selectedFromModule.filter((item) => item.name.toLowerCase().includes(this.filterAdded.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name))
      }

      return [...this.selectedFromModule].sort((a, b) => a.name.localeCompare(b.name))
    },
    sortedFromBe() {
      return [...this.listFromBE].sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  watch: {
    selectedFromModule: {
      handler(val) {
        if (!this.lock) this.$emit('setValues', val ? val : null)
      },
      deep: true
    },
    searchFromBE: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchResources(
            val,
            fetcherDataByType[this.type].func,
            this.resourcesTypesNoSystem.includes(this.type)
          )
        } else if (!val) {
          this.listFromBE = []
        }
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    openItem(item) {
      const link = `/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[this.type]}/${item.id}`

      window.open(link, '_blank')
    },
    async addFromBeToModule() {
      if (!this.selectedFromBE) return

      const tempSelected = this.listFromBE.find((x) => x.id === this.selectedFromBE)

      if (this.selectedFromModule.map((x) => x.id).includes(tempSelected.id)) return

      this.sendDataEmit(tempSelected)

      this.searchingForConnectedResources = true

      try {
        this.dataForModal = await getFormattedRelatedResources(
          tempSelected,
          this.type,
          this.allResources,
          true
        )

        if (this.dataForModal.length > 0) {
          this.showModal = true
        }
      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
      } finally {
        this.searchingForConnectedResources = false
      }

      this.listFromBE = this.listFromBE.filter((x) => x.id !== tempSelected.id)
    },
    removeFromSelected(id) {
      this.listFromBE.push(this.selectedFromModule.find((x) => x.id === id))
      this.selectedFromModule = this.selectedFromModule.filter((x) => x.id !== id)
    },
    afterModal(data) {
      if (data.length > 0) {
        data.forEach((item) => {
          this.sendAdditionalDataEmit(item.type, item.data)
        })
      }
    },
    sendAdditionalDataEmit(type, tempSelected) {
      this.$emit('setAdditionalValues', { type, data: tempSelected })
    },
    sendDataEmit(tempSelected) {
      if (this.selectedFromModule.map((x) => x.id).includes(tempSelected.id)) return

      if (tempSelected) this.listFromModule.push(tempSelected)
      if (tempSelected) this.selectedFromModule.push(tempSelected)
      this.$emit('makeDirty', true)
    },
    init() {
      this.listFromModule = structuredClone(this.defaultValues)
      this.selectedFromModule = structuredClone(this.defaultValues)

      setTimeout(() => {
        this.lock = false
      }, 100)
    },
    searchResources(val = '', apiCall, noSystem = false) {
      this.isLoading = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      if (noSystem) obj.isSystem = false

      apiCall(obj)
        .then((res) => {
          this.listFromBE = res.data.data.items.filter((item) => !this.listFromModule.some((entry) => entry.id === item.id))
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    }
  }
}
</script>
<style scoped lang="scss">
.list-div {
  width: 100%;
  border: 1px solid var(--v-accent-base);
  border-radius: 6px;
  max-height: 40vh;
  overflow: auto;
}
</style>
